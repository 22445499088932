@import "bootstrap.scss";
@import "color.scss";

@import "themify.scss";
@import "whether-icon/whether-icon";

@import "flag-icon/flag-icon-base";
@import "flag-icon/flag-icon-more";
@import "flag-icon/flag-icon-list";

@import "icoicon/icons";
@import "font-awesome.scss";

@import "chartist/chartist-settings";
@import "chartist/chartist";

.page-header-left h3,
h5 {
	font-weight: 700 !important;
}

.form-group label,
.form-row label {
	font-weight: 600;
}

.form-group input[type="checkbox"] ~ label,
.form-row input[type="checkbox"] ~ label,
.form-group input[type="radio"] ~ label,
.form-row input[type="radio"] ~ label {
	font-weight: 400;
}

.react-datepicker__day.react-datepicker__day--010.react-datepicker__day--keyboard-selected.react-datepicker__day--weekend,
.react-datepicker__day.react-datepicker__day--010.react-datepicker__day--keyboard-selected {
	color: #000;
}
