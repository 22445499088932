$border-color: #e3e8f0;
$font-color: #2d3748;

#refreshTicketsBtn {
	border: 1px solid $border-color;
	font-family: Nunito;
	font-size: 16px;
	font-weight: 600;
	margin-right: 10px;

	padding: 8px 16px 8px 16px;

	color: $font-color;
}

#refreshTicketsBtn > span {
	width: 66px;
	height: 24px;

	margin-left: 9px;
}
