@import "../../../../index.scss";

.u-pearl.error .u-pearl-number {
	border-color: $danger-color !important;
	background-color: $danger-color !important;
}

.u-pearl.current .u-pearl-number {
	border-color: $primary-color !important;
	background-color: $primary-color !important;
	color: $white !important;
}
