/* Arrows */
.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: 1;
    color: rgba(0, 60, 255, 0.726);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -23px;
}
[dir='rtl'] .slick-prev
{
    right: -23px;
    left: auto;
}
.slick-next
{
    right: -23px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -23px;
}









