/**=====================
     01. General CSS Start
==========================**/
//Custom css Start

body {
	background-color: $body-bg-color;
	font-size: $body-font-size;
	overflow-x: hidden;
	font-family: $font-nunito, $font-work-sans, $font-serif;
	color: $theme-body-font-color;
}
ul {
	padding-left: $ul-padding-left;
	list-style-type: none;
	margin-bottom: 0;
	padding-right: $ul-padding-right;
}
* a {
	color: $primary-color;
	&.btn-primary,
	&.btn-secondary,
	&.btn-success,
	&.btn-info,
	&.btn-warning,
	&.btn-danger {
		color: $white;
	}
}
*.btn:focus {
	box-shadow: $btn-focus-box-shadow !important;
}
p {
	font-size: $paragraph-font-size;
	line-height: $paragraph-line-height;
	letter-spacing: $paragraph-letter-spacing;
}
code {
	color: $code-tag-color !important;
	background-color: $code-tag-bg-color;
	padding: $code-tag-padding;
	margin: $code-tag-margin;
	border-radius: $code-tag-border-radious;
}
blockquote {
	border-left: $blockquote-border;
	padding: $blockquote-padding;
}
blockquote {
	&.text-center {
		border: none;
		padding: $blockquote-padding;
	}
}
blockquote {
	&.text-right {
		border-left: none;
		border-right: $blockquote-border;
		padding: $blockquote-padding;
	}
}
:focus {
	outline-color: $all-focus-outline-color;
}
.disabled {
	cursor: not-allowed;
	opacity: 0.5;
}
svg {
	vertical-align: baseline;
}
input {
	&:focus {
		outline-color: $transparent-color;
	}
}
:not(pre) {
	> code[class*="language-"],
	pre[class*="language-"] {
		background: $light-color;
	}
}
.code-box-copy__btn {
	background-color: rgba($primary-color, 0.2);
	border: 1px solid rgba($primary-color, 0.3);
}
//Custom css End

//Typography css start

.typography {
	h1,
	.h1,
	h2,
	.h2,
	h3,
	.h3,
	h4,
	.h4,
	h5,
	.h5,
	h6,
	.h6 {
		margin: 20px 0;
		margin-top: 0;
		&:last-child {
			margin-bottom: 0;
		}
	}
	small {
		padding-left: $small-tag-padding;
		color: $small-tag-color;
	}
}
pre {
	background-color: $pre-tag-bg-color;
	padding: $pre-tag-padding;
}

a {
	&:hover {
		text-decoration: none;
		color: $primary-color;
	}
}
//Typography css end

.owl-theme {
	.owl-dots {
		.owl-dot {
			span {
				background: lighten($primary-color, 20%);
			}
			&.active,
			&:hover {
				span {
					background: $primary-color;
				}
			}
		}
	}
	.owl-nav {
		&.disabled {
			& + .owl-dots {
				margin-bottom: -10px;
				margin-top: 25px;
			}
		}
	}
}

.click2edit {
	& ~ .note-editor {
		&.note-frame {
			margin-bottom: 30px;
		}
	}
}
.note-editor {
	&.note-frame {
		border-color: $light-color;
	}
}
.note-editor {
	&.note-frame {
		border-color: $light-color;
	}
}
// modal page
.modal-dialog {
	.modal-content {
		.modal-body {
			p {
				a {
					margin-right: 0;
				}
			}
		}
	}
}
// index page notify
.alert-theme {
	button {
		top: 33px !important;
		right: 30px !important;
		color: $primary-color;
	}
	span {
		+ span {
			+ span {
				border: 1px solid $primary-color;
				background-color: $white;
				border-radius: 4px;
			}
		}
	}
	i {
		background-color: $primary-color;
		color: $white;
		padding: 20px;
		margin-right: 20px !important;
	}
}
.alert-copy {
	border: 1px solid $primary-color;
	line-height: 1;
	display: inline-block;
	width: 300px;
	color: $white;
	background-color: $primary-color;
	box-shadow: 3px 3px 5px 0 #9b9b9b;
	.close {
		box-shadow: none;
		color: $white;
		line-height: 1.4;
		font-weight: 100;
	}
}

// loader css
@keyframes whirly-loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.whirly-loader {
	&:not(:required) {
		overflow: hidden;
		position: relative;
		text-indent: -9999px;
		display: inline-block;
		width: 8px;
		height: 8px;
		background: transparent;
		border-radius: 100%;
		box-shadow: $primary-color 0px 26px 0 6px,
			$primary-color 0.90971px 26.05079px 0 5.93333px,
			$primary-color 1.82297px 26.06967px 0 5.86667px,
			$primary-color 2.73865px 26.05647px 0 5.8px,
			$primary-color 3.65561px 26.01104px 0 5.73333px,
			$primary-color 4.57274px 25.93327px 0 5.66667px,
			$primary-color 5.48887px 25.8231px 0 5.6px,
			$primary-color 6.40287px 25.68049px 0 5.53333px,
			$primary-color 7.31358px 25.50548px 0 5.46667px,
			$primary-color 8.21985px 25.2981px 0 5.4px,
			$primary-color 9.12054px 25.05847px 0 5.33333px,
			$primary-color 10.01448px 24.78672px 0 5.26667px,
			$primary-color 10.90054px 24.48302px 0 5.2px,
			$primary-color 11.77757px 24.1476px 0 5.13333px,
			$primary-color 12.64443px 23.78072px 0 5.06667px,
			$primary-color 13.5px 23.38269px 0 5px,
			$primary-color 14.34315px 22.95384px 0 4.93333px,
			$primary-color 15.17277px 22.49455px 0 4.86667px,
			$primary-color 15.98776px 22.00526px 0 4.8px,
			$primary-color 16.78704px 21.48643px 0 4.73333px,
			$primary-color 17.56953px 20.93855px 0 4.66667px,
			$primary-color 18.33418px 20.36217px 0 4.6px,
			$primary-color 19.07995px 19.75787px 0 4.53333px,
			$primary-color 19.80582px 19.12626px 0 4.46667px,
			$primary-color 20.5108px 18.468px 0 4.4px,
			$primary-color 21.1939px 17.78379px 0 4.33333px,
			$primary-color 21.85416px 17.07434px 0 4.26667px,
			$primary-color 22.49067px 16.34043px 0 4.2px,
			$primary-color 23.10251px 15.58284px 0 4.13333px,
			$primary-color 23.68881px 14.80241px 0 4.06667px,
			$primary-color 24.24871px 14px 0 4px,
			$primary-color 24.7814px 13.1765px 0 3.93333px,
			$primary-color 25.28607px 12.33284px 0 3.86667px,
			$primary-color 25.76198px 11.46997px 0 3.8px,
			$primary-color 26.2084px 10.58888px 0 3.73333px,
			$primary-color 26.62462px 9.69057px 0 3.66667px,
			$primary-color 27.01001px 8.77608px 0 3.6px,
			$primary-color 27.36392px 7.84648px 0 3.53333px,
			$primary-color 27.68577px 6.90284px 0 3.46667px,
			$primary-color 27.97502px 5.94627px 0 3.4px,
			$primary-color 28.23116px 4.97791px 0 3.33333px,
			$primary-color 28.4537px 3.99891px 0 3.26667px,
			$primary-color 28.64223px 3.01042px 0 3.2px,
			$primary-color 28.79635px 2.01364px 0 3.13333px,
			$primary-color 28.91571px 1.00976px 0 3.06667px,
			$primary-color 29px 0px 0 3px,
			$primary-color 29.04896px -1.01441px 0 2.93333px,
			$primary-color 29.06237px -2.03224px 0 2.86667px,
			$primary-color 29.04004px -3.05223px 0 2.8px,
			$primary-color 28.98185px -4.07313px 0 2.73333px,
			$primary-color 28.88769px -5.09368px 0 2.66667px,
			$primary-color 28.75754px -6.1126px 0 2.6px,
			$primary-color 28.59138px -7.12863px 0 2.53333px,
			$primary-color 28.38926px -8.14049px 0 2.46667px,
			$primary-color 28.15127px -9.1469px 0 2.4px,
			$primary-color 27.87755px -10.1466px 0 2.33333px,
			$primary-color 27.56827px -11.1383px 0 2.26667px,
			$primary-color 27.22365px -12.12075px 0 2.2px,
			$primary-color 26.84398px -13.09268px 0 2.13333px,
			$primary-color 26.42956px -14.05285px 0 2.06667px,
			$primary-color 25.98076px -15px 0 2px,
			$primary-color 25.49798px -15.93291px 0 1.93333px,
			$primary-color 24.98167px -16.85035px 0 1.86667px,
			$primary-color 24.43231px -17.75111px 0 1.8px,
			$primary-color 23.85046px -18.63402px 0 1.73333px,
			$primary-color 23.23668px -19.49789px 0 1.66667px,
			$primary-color 22.5916px -20.34157px 0 1.6px,
			$primary-color 21.91589px -21.16393px 0 1.53333px,
			$primary-color 21.21024px -21.96384px 0 1.46667px,
			$primary-color 20.4754px -22.74023px 0 1.4px,
			$primary-color 19.71215px -23.49203px 0 1.33333px,
			$primary-color 18.92133px -24.2182px 0 1.26667px,
			$primary-color 18.10379px -24.91772px 0 1.2px,
			$primary-color 17.26042px -25.58963px 0 1.13333px,
			$primary-color 16.39217px -26.23295px 0 1.06667px,
			$primary-color 15.5px -26.84679px 0 1px,
			$primary-color 14.58492px -27.43024px 0 0.93333px,
			$primary-color 13.64796px -27.98245px 0 0.86667px,
			$primary-color 12.69018px -28.50262px 0 0.8px,
			$primary-color 11.7127px -28.98995px 0 0.73333px,
			$primary-color 10.71663px -29.4437px 0 0.66667px,
			$primary-color 9.70313px -29.86317px 0 0.6px,
			$primary-color 8.67339px -30.2477px 0 0.53333px,
			$primary-color 7.6286px -30.59666px 0 0.46667px,
			$primary-color 6.57001px -30.90946px 0 0.4px,
			$primary-color 5.49886px -31.18558px 0 0.33333px,
			$primary-color 4.41643px -31.42451px 0 0.26667px,
			$primary-color 3.32401px -31.6258px 0 0.2px,
			$primary-color 2.22291px -31.78904px 0 0.13333px,
			$primary-color 1.11446px -31.91388px 0 0.06667px,
			$primary-color 0px -32px 0 0px,
			$primary-color -1.11911px -32.04713px 0 -0.06667px,
			$primary-color -2.24151px -32.05506px 0 -0.13333px,
			$primary-color -3.36582px -32.02361px 0 -0.2px,
			$primary-color -4.49065px -31.95265px 0 -0.26667px,
			$primary-color -5.61462px -31.84212px 0 -0.33333px,
			$primary-color -6.73634px -31.69198px 0 -0.4px,
			$primary-color -7.8544px -31.50227px 0 -0.46667px,
			$primary-color -8.9674px -31.27305px 0 -0.53333px,
			$primary-color -10.07395px -31.00444px 0 -0.6px,
			$primary-color -11.17266px -30.69663px 0 -0.66667px,
			$primary-color -12.26212px -30.34982px 0 -0.73333px,
			$primary-color -13.34096px -29.96429px 0 -0.8px,
			$primary-color -14.4078px -29.54036px 0 -0.86667px,
			$primary-color -15.46126px -29.07841px 0 -0.93333px,
			$primary-color -16.5px -28.57884px 0 -1px,
			$primary-color -17.52266px -28.04212px 0 -1.06667px,
			$primary-color -18.52792px -27.46878px 0 -1.13333px,
			$primary-color -19.51447px -26.85936px 0 -1.2px,
			$primary-color -20.48101px -26.21449px 0 -1.26667px,
			$primary-color -21.42625px -25.53481px 0 -1.33333px,
			$primary-color -22.34896px -24.82104px 0 -1.4px,
			$primary-color -23.2479px -24.07391px 0 -1.46667px,
			$primary-color -24.12186px -23.29421px 0 -1.53333px,
			$primary-color -24.96967px -22.48279px 0 -1.6px,
			$primary-color -25.79016px -21.64052px 0 -1.66667px,
			$primary-color -26.58223px -20.76831px 0 -1.73333px,
			$primary-color -27.34477px -19.86714px 0 -1.8px,
			$primary-color -28.07674px -18.938px 0 -1.86667px,
			$primary-color -28.7771px -17.98193px 0 -1.93333px,
			$primary-color -29.44486px -17px 0 -2px,
			$primary-color -30.07908px -15.99333px 0 -2.06667px,
			$primary-color -30.67884px -14.96307px 0 -2.13333px,
			$primary-color -31.24325px -13.91039px 0 -2.2px,
			$primary-color -31.7715px -12.83652px 0 -2.26667px,
			$primary-color -32.26278px -11.74269px 0 -2.33333px,
			$primary-color -32.71634px -10.63018px 0 -2.4px,
			$primary-color -33.13149px -9.5003px 0 -2.46667px,
			$primary-color -33.50755px -8.35437px 0 -2.53333px,
			$primary-color -33.84391px -7.19374px 0 -2.6px,
			$primary-color -34.14px -6.0198px 0 -2.66667px,
			$primary-color -34.39531px -4.83395px 0 -2.73333px,
			$primary-color -34.60936px -3.63759px 0 -2.8px,
			$primary-color -34.78173px -2.43218px 0 -2.86667px,
			$primary-color -34.91205px -1.21916px 0 -2.93333px,
			$primary-color -35px 0px 0 -3px,
			$primary-color -35.04531px 1.22381px 0 -3.06667px,
			$primary-color -35.04775px 2.45078px 0 -3.13333px,
			$primary-color -35.00717px 3.6794px 0 -3.2px,
			$primary-color -34.92345px 4.90817px 0 -3.26667px,
			$primary-color -34.79654px 6.13557px 0 -3.33333px,
			$primary-color -34.62643px 7.36007px 0 -3.4px,
			$primary-color -34.41316px 8.58016px 0 -3.46667px,
			$primary-color -34.15683px 9.79431px 0 -3.53333px,
			$primary-color -33.85761px 11.001px 0 -3.6px,
			$primary-color -33.5157px 12.19872px 0 -3.66667px,
			$primary-color -33.13137px 13.38594px 0 -3.73333px,
			$primary-color -32.70493px 14.56117px 0 -3.8px,
			$primary-color -32.23675px 15.72291px 0 -3.86667px,
			$primary-color -31.72725px 16.86968px 0 -3.93333px,
			$primary-color -31.17691px 18px 0 -4px,
			$primary-color -30.58627px 19.11242px 0 -4.06667px,
			$primary-color -29.95589px 20.2055px 0 -4.13333px,
			$primary-color -29.28642px 21.27783px 0 -4.2px,
			$primary-color -28.57852px 22.32799px 0 -4.26667px,
			$primary-color -27.83295px 23.35462px 0 -4.33333px,
			$primary-color -27.05047px 24.35635px 0 -4.4px,
			$primary-color -26.23192px 25.33188px 0 -4.46667px,
			$primary-color -25.37819px 26.27988px 0 -4.53333px,
			$primary-color -24.49018px 27.1991px 0 -4.6px,
			$primary-color -23.56888px 28.0883px 0 -4.66667px,
			$primary-color -22.6153px 28.94626px 0 -4.73333px,
			$primary-color -21.6305px 29.77183px 0 -4.8px,
			$primary-color -20.61558px 30.56385px 0 -4.86667px,
			$primary-color -19.57168px 31.32124px 0 -4.93333px,
			$primary-color -18.5px 32.04294px 0 -5px,
			$primary-color -17.40175px 32.72792px 0 -5.06667px,
			$primary-color -16.27818px 33.37522px 0 -5.13333px,
			$primary-color -15.1306px 33.98389px 0 -5.2px,
			$primary-color -13.96034px 34.55305px 0 -5.26667px,
			$primary-color -12.76875px 35.08186px 0 -5.33333px,
			$primary-color -11.55724px 35.56951px 0 -5.4px,
			$primary-color -10.32721px 36.01527px 0 -5.46667px,
			$primary-color -9.08014px 36.41843px 0 -5.53333px,
			$primary-color -7.81748px 36.77835px 0 -5.6px,
			$primary-color -6.54075px 37.09443px 0 -5.66667px,
			$primary-color -5.25147px 37.36612px 0 -5.73333px,
			$primary-color -3.95118px 37.59293px 0 -5.8px,
			$primary-color -2.64145px 37.77443px 0 -5.86667px,
			$primary-color -1.32385px 37.91023px 0 -5.93333px;
		animation: whirly-loader 1.25s infinite linear;
		transform-origin: 50% 50%;
	}
}

.ace-editor {
	height: 400px;
	width: 100%;
}
.editor-toolbar {
	width: 100% !important;
}
.CodeMirror {
	top: 0 !important;
}
// vertical menu
.pixelstrap {
	ul {
		a {
			color: $theme-body-sub-title-color;
			&:hover,
			&:focus,
			&:active {
				color: $primary-color;
			}
		}
	}
}
// contact
.contact-profile-form {
	padding-bottom: 30px;
}
.contact-search {
	.form-group {
		width: 100%;
		.form-control {
			height: 35px;
		}
		&:before {
			left: 67px !important;
			top: 5px !important;
		}
		&:after {
			left: 37px !important;
			top: 8px !important;
		}
	}
}
.contact-filter {
	display: flex;
	align-items: center;
	.input-range,
	h5 {
		margin-top: -15px;
	}
	h5 {
		width: 224px;
	}
}
.contact-table {
	table {
		th,
		td {
			border-top: none;
			padding: 30px;
			vertical-align: middle;
		}
		tr {
			td {
				border-bottom: 1px solid $light-semi-gray;
				h6 {
					margin-bottom: 0;
				}
				.btn-primary {
					color: $white;
					&:hover {
						color: $white;
					}
				}
			}
			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}
	}
}
.change-img-button {
	margin: 0 auto;
}
.contact-profile {
	position: relative;
	.icon-wrapper {
		position: absolute;
		top: 73%;
		left: 53%;
		padding: 10px;
		background-color: #fff;
		border-radius: 25px;
		cursor: pointer;
	}
}
.upload {
	position: absolute;
	width: 36px;
	left: 0;
	right: 0;
	opacity: 0;
}

.search-not-found {
	align-items: center;
	justify-content: center;
	height: 631px;
	display: flex;
	.second-search {
		width: 200px;
	}
}

/**=====================
      01. General CSS Ends
==========================**/
