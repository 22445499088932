.auth-bg-vanta {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	padding: 50px 30px;
}

.auth--validation-alert {
	color: #ff5370;
	font-size: small;
	display: block;
	margin-top: 5px;
}

.auth--valid-feedback {
	color: rgb(5, 92, 17);
	font-size: small;
	display: block;
	margin-top: 5px;
}

.auth--required-symbol {
	color: red;
	color: #721c24;
}

.auth--card {
	opacity: 0.9;
}
