table {
	width: 100%;
}
.th.url {
	width: 40%;
}
.th.username,
.th.password {
	width: 25%;
}
