.table {
	overflow: hidden;
	margin-top: 20px;
}
.headerDown,
.headerUnique {
	border-left: 2px solid #ddd;
	border-right: 2px solid #ddd;
	vertical-align: middle;
}
.header,
.digits {
	text-align: center;
	white-space: nowrap;
}
.toRight {
	border-right: 2px solid #ddd;
}

.color-darkgold {
	color: #b8860b !important;
}
